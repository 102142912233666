/* eslint-disable */
const map = require(`../../routes/map.json`)

const fixInternalLinks = (guid, locale) => {
  if (map[guid]) {
    if (map[guid][locale]) {
      return map[guid][locale]
    }
  }

  if (guid.type === 'Link.document') {
    return guid.value.document.slug
  }

  // console.error('NOT ABLE TO DETERMINE ROUTE')
  // console.table({"GUID": guid, "Locale": locale})

  return null
}

export default fixInternalLinks
