export default {
  colors: {
    'transparent': 'transparent',
    'black': '#3a4246',
    'grey': '#B5B5B5',
    'grey-darker': '#3A4246',
    'white': '#ffffff',
    'blog-text': '#7A8184',
    'border-blue': '#4D8DCD',
    'blue': '#3490dc',
    'light-blue': 'rgba(77,141,205,0.05)'
  },
  space: [
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '5rem',
    '6rem',
    '8rem',
  ],
  breakpoints: [ '32em', '40em', '56em', '64em', '80em' ]
}
