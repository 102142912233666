/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { IntlProvider, addLocaleData } from 'react-intl'

import '../../../assets/css/normalize.css'
import '../../../assets/css/global.css'

// Locale data
import enData from 'react-intl/locale-data/en'
import frData from 'react-intl/locale-data/fr'
import cnData from 'react-intl/locale-data/zh'

// Messages
import en from '../../../i18n/en.json'
import fr from '../../../i18n/fr.json'
import chs from '../../../i18n/zh_hans.json'
import cht from '../../../i18n/zh_hant.json'

import theme from './theme'

const messages = { en, fr, 'zh-hans': chs, 'zh-hant': cht }

addLocaleData([...enData, ...frData, ...cnData])

const flattenMessages = ((nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((_messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      Object.assign(_messages, { [prefixedKey]: value })
    } else {
      Object.assign(_messages, flattenMessages(value, prefixedKey))
    }

    return _messages
  }, {})
})

function check_webp_feature(feature, callback) {
  const kTestImages = {
    alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA=="
  };
  if (typeof window !== 'undefined') {
    const img = new Image();
    img.onload = function () {
      const result = (img.width > 0) && (img.height > 0);
      callback(feature, result);
    };
    img.onerror = function () {
      callback(feature, false);
    };
    img.src = `data:image/webp;base64,${kTestImages[feature]}`;
  } else {
    callback(feature, false)
  }
}

check_webp_feature('alpha', (_, result) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.setItem("webpsupport", result);
  }
})

const locales = ['en', 'fr', 'zh-hans', 'zh-hant'];

const getLocaleUrl = (locale, urls) => {
  if (locale === 'en') {
    if (urls.en) {
      return `${process.env.SITE}${urls.en}`
    }
  }

  if (locale === 'fr') {
    if (urls.fr) {
      return `${process.env.SITE}${urls.fr}`
    }
  }

  if (locale === 'zh-hant') {
    if (urls.zh_hant) {
      return `${process.env.SITE}${urls.zh_hant}`
    }
  }

  if (locale === 'zh-hans') {
    if (urls.zh_hans) {
      return `${process.env.SITE}${urls.zh_hans}`
    }
  }

  return ''
}

const getLinkArray = (locale, urls) => {
  const links = []

  locales.forEach(_locale => {
    links.push({
      rel: 'alternate',
      href: getLocaleUrl(_locale, urls),
      hrefLang: _locale
    })
  })

  links.push({
    rel: 'canonical',
    href: getLocaleUrl(locale, urls)
  })

  links.push({
    rel: 'alternate',
    href: getLocaleUrl('en', urls),
    hrefLang: 'x-default'
  })

  links.push({
    rel: 'preconnect',
    href: '//img.imageboss.me'
  })

  return links
}

const Layout = ({
  locale,
  children,
  meta,
  theme_color,
  urls,
  ogimage,
  opengraph
}) => (
  <React.Fragment>
    <Helmet
      title={meta.title ? meta.title : null}
      defaultTitle="ROYALE®"
      meta={[
        { name: 'description', content: meta.description ? meta.description : '' },
        { name: 'keywords', content: meta.keywords ? meta.keywords : '' },
        { name: 'theme-color', content: theme_color }
      ]}
      link={getLinkArray(locale, urls)}
    >
      <script async src={`https://apps.bazaarvoice.com/deployments/royale-ca/main_site/production/${(locale === 'fr') ? 'fr_CA' : 'en_CA' }/bv.js`}></script>
      <html lang={locale} />
      {ogimage ? <meta property="og:image" content={ogimage} /> : null}
      {opengraph ? <link rel="opengraph" href={opengraph} /> : null}
      <link rel="stylesheet" href="https://use.typekit.net/pzx7dtu.css" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
        <React.Fragment>
          {children}
        </React.Fragment>
      </IntlProvider>
    </ThemeProvider>
    <img src="https://ib.adnxs.com/pixie?pi=bad5b72e-dded-4a24-804f-aaf8ca125c0f&e=PageView&script=0" width="1" height="1" style={{display:'none'}}/>
  </React.Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.shape({
    description: PropTypes.string,
    keywords: PropTypes.string
  }).isRequired,
  theme_color: PropTypes.string,
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired
  }).isRequired,
  ogimage: PropTypes.string,
  opengraph: PropTypes.string
}

Layout.defaultProps = {
  theme_color: '#4c8dcd',
  ogimage: null,
  opengraph: null
}

export default Layout
