/* eslint-disable */
import React from 'react';
import PropTypes from "prop-types";
import striptags from "striptags";

function buildSrcSet(url) {
  const MAX_SIZE = 1920;
  const INCREMENT_PERCENTAGE = 8;
  const resolutions = [];
  let resolution = 100;
  let srcSet = "";

  const ensureEven = (n) => 2 * Math.round(n / 2);

  while (resolution <= MAX_SIZE) {
    resolutions.push(ensureEven(resolution));
    resolution *= 1 + (INCREMENT_PERCENTAGE / 100) * 2;
  }

  resolutions.push(MAX_SIZE);

  const len = resolutions.length;
  for (let i = 0; i < len; i += 1) {
    srcSet += `https://d1qh5bvswnbbnx.cloudfront.net/hawk/s:${resolutions[i]}:0:0:0/plain/${url} ${resolutions[i]}w, `
  }
  srcSet = srcSet.slice(0, -2);

  return srcSet;
}

const Image = ({url, alt, loading, ...props}) => {
  const fixedUrl = url;
  if (url) {
    // fixedUrl = url.replace('https://prodweb.royaleapi.irvingconsumerproducts.com', 'http://35.183.150.248')
  }
  return (
    <img
      src={`${fixedUrl}`}
      loading={loading}
      alt={striptags(alt)
        .replace(/&reg;/gi,'®')
        .replace(/&nbsp;/gi,' ')
      }
      {...props}
    />
  )
}

Image.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  loading: PropTypes.string
}

Image.defaultProps = {
  alt: '',
  loading: 'lazy'
}

export default Image
