/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { Link } from "gatsby"
import {injectIntl} from 'react-intl';
import styled from '@emotion/styled'

import fixInternalLinks from '../../../helpers/fixInternalLinks'
import sendEvent from '../../../helpers/sendAnalytics'
import Icon from "../Icon";

const MenuItemFloatStyle = css`
  ${tw`
    border-0 border-b border-solid border-transparent
    font-montserrat
    text-white
    text-sm
    leading-tight
    no-underline
  `}
  -webkit-transition: border 200ms ease;
  transition: border 200ms ease;

  &:hover,
  &:focus {
    ${tw`
      border-white
    `}
  }
`

const MenuItemStyle = css`
  ${tw`
    border-0 border-b border-solid border-transparent
    font-montserrat
    text-black
    text-sm
    leading-tight
    no-underline
  `}
  -webkit-transition: border 200ms ease;
  transition: border 200ms ease;

  &:hover,
  &:focus {
    ${tw`
      border-black
    `}
  }
`

const MenuSubItemStyle = styled.div`
  ${tw`
    border-0 border-b border-solid border-transparent
    font-montserrat
    text-black
    leading-tight
    no-underline
  `}
  -webkit-transition: border 200ms ease;
  transition: border 200ms ease;
  font-size: 16px;
  display: flex;
  justify-content: center;
  border-color: ${props => props.open ? '#3a4246' : 'transparent'};

  &:hover,
  &:focus {
    ${tw`
      border-black
    `}
  }
`

const DropDownIcon = styled.div`
  margin-left: 10px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${props => props.open ? 'rotate(180deg);' : 'none'};
`

const MenuItem = ({children, to, type, internal, target, intl, disabled, open, ...props}) => {
  if (internal && !disabled) {
    return (
      <Link
        to={fixInternalLinks(to, intl.locale)}
        {...props}
        css={(type === 'float') ? MenuItemFloatStyle : MenuItemStyle}
        open={open}
      >
        {children}
      </Link>
    )
  }

  if(disabled){
    return(
      <MenuSubItemStyle
        open={open}
      >
        {children}
        <DropDownIcon open={open}>
          <Icon icon="chevron" down size={12} strokeWidth={2} width="12" />
        </DropDownIcon>
      </MenuSubItemStyle>
    )
  }

  return (
    <a
      href={to}
      {...props}
      css={(type === 'float') ? MenuItemFloatStyle : MenuItemStyle}
      target={target}
      rel="noopener noreferrer"
      onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: (intl.locale === 'en') ? 'Careers_External_Link' : 'Careers_External_Link_FR'}})}
    >
      {children}
    </a>
  )
}

MenuItem.propTypes = {
  // Either blue or outlined
  to: PropTypes.string.isRequired,
  // Is the menu item floating on images
  type: PropTypes.string,
  // Any additional menu elements (used for homepage)
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  internal: PropTypes.bool
}

MenuItem.defaultProps = {
  type: '',
  children: null,
  internal: false
}

export default injectIntl(MenuItem)
